<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col
          md="6"
          sm="8"
        >
          <b-card
            no-body
            class="m-4"
          >
            <b-card-body class="p-4">
              <b-form
                novalidate
                @submit.prevent="onSubmit"
              >
                <h1>Регистрация</h1>
                <p class="text-muted">
                  Создайте Ваш аккаунт
                </p>

                <b-form-group
                  id="emailInputGroup1"
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model.trim="$v.form.email.$model"
                    type="email"
                    name="email"
                    :state="chkState('email')"
                    aria-describedby="input1LiveFeedback1"
                    placeholder="Email"
                    autocomplete="email"
                    autofocus
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback1">
                    Это поле обязательно для заполнения, так же адрес электронной почты должен быть действительным
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="passInputGroup2"
                  label="Пароль"
                  label-for="raw_password"
                >
                  <b-form-input
                    id="raw_password"
                    v-model.trim="$v.form.rawPassword.$model"
                    type="password"
                    name="raw_password"
                    :state="chkState('rawPassword')"
                    aria-describedby="input1LiveFeedback2"
                    placeholder="Пароль"
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback2">
                    Это поле обязательно для заполнения, пароль должен содержать: цифры, прописные и строчные буквы, минимум 8 символов
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="passInputGroup3"
                  label="Подтверждение пароля"
                  label-for="repeat_password"
                >
                  <b-form-input
                    id="repeat_password"
                    v-model.trim="$v.form.repeatPassword.$model"
                    type="password"
                    name="repeat_password"
                    :state="chkState('repeatPassword')"
                    aria-describedby="input1LiveFeedback3"
                    placeholder="Подтверждение пароля"
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback3">
                    Пароли должны совпадать
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="nameInputGroup4"
                  label="Название компании"
                  label-for="company"
                >
                  <b-form-input
                    id="name"
                    v-model.trim="$v.form.name.$model"
                    type="text"
                    name="name"
                    :state="chkState('name')"
                    aria-describedby="input1LiveFeedback4"
                    placeholder="Название компании"
                    autocomplete="company"
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback4">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="nameInputGroupINN"
                  label="ИНН"
                  label-for="inn"
                >
                  <b-form-input
                    id="inn"
                    v-model.trim="$v.form.inn.$model"
                    type="text"
                    name="inn"
                    :state="chkState('inn')"
                    aria-describedby="input1LiveFeedbackINN"
                    placeholder="ИНН компании"
                    autocomplete="inn"
                  />
                  <b-form-invalid-feedback id="requisitesINN ">
                    <span v-if="!$v.form.inn.isInn">ИНН должен содержать 10 или 12 цифр <br></span>
                    <span v-if="!$v.form.inn.isInnFormat">Неправильный формат ИНН</span>
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="phoneInputGroup5"
                  label="Контактный телефон"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model.trim="$v.form.phone.$model"
                    v-mask="'+7 (999) 999-99-99'"
                    type="text"
                    name="phone"
                    :state="chkState('phone')"
                    aria-describedby="input1LiveFeedback5"
                    placeholder="Контактный телефон"
                    autocomplete="phone"
                    @blur="phoneValidate($v.form.phone.$model)"
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback5">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="infoInputGroup6"
                  label="Краткая информация о компании"
                  label-for="about"
                >
                  <b-form-textarea
                    id="info"
                    v-model.trim="$v.form.info.$model"
                    type="text"
                    name="info"
                    rows="4"
                    :state="chkState('info')"
                    aria-describedby="input1LiveFeedback6"
                    placeholder="Краткая информация о компании"
                    autocomplete="about, info"
                    no-resize
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback6">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--<b-form-group>-->
                <!--<vue-recaptcha-->
                <!--ref="recaptcha"-->
                <!--size="invisible"-->
                <!--:sitekey="sitekey"-->
                <!--@verify="register"-->
                <!--@expired="onCaptchaExpired"-->
                <!--/>-->
                <!--</b-form-group>-->

                <!--<b-form-group :invalid-feedback="invCheck" :state="chkState('accept')">-->
                <!--<b-form-checkbox id="accept"-->
                <!--v-model="$v.form.accept.$model"-->
                <!--:state="chkState('accept')"-->
                <!--aria-describedby="input1LiveFeedback7">-->
                <!--<strong>I accept the terms of use</strong>-->
                <!--</b-form-checkbox>-->
                <!--</b-form-group>-->

                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="loading"
                  :class="{'loading' : loading}"
                >
                  Подтвердить
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>

  <!--div v-if="isLoading" class="row col">
    <p>Loading...</p>
  </div>

  <div v-else-if="hasError" class="row col">
    <error-message :error="error"></error-message>
  </div-->
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength, maxLength, minValue, numeric, email, sameAs, helpers} from 'vuelidate/lib/validators';
import {isInn, isInnFormat} from '../components/mixins/validation';
import VueRecaptcha from 'vue-recaptcha';
import notifications from '../components/mixins/notifications';
import {contractorCreate} from '../services/api';

// const mustAccept = value => {
//   return Boolean(value)
// }

const strongPass = helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);

const formShape = {
  email: '',
  rawPassword: '',
  repeatPassword: '',
  name: '',
  phone: '',
  info: '',
  inn: '',
  // captcha: "",
  // accept: false
};

export default {
  name: 'Registration',
  components: {
    VueRecaptcha,
  },
  mixins: [validationMixin, notifications],
  data() {
    return {
      form: Object.assign({}, formShape),
      feedBack: 'secondary',
      sitekey: 'ВАШ SITE KEY',
      loading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    // invCheck() { return 'You must accept before submitting' },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      rawPassword: {
        required,
        minLength: minLength(8),
        strongPass,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('rawPassword'),
      },
      name: {
        required,
      },
      phone: {
        required,
        minLength: minLength(12),
      },
      info: {
        required,
      },
      inn: {
        isInn,
        isInnFormat,
      },
      // captcha: {
      //   required
      // },
      // accept: {
      //   required,
      //   mustAccept
      // }
    },
  },
  methods: {
    phoneValidate(e) {
      if (/_/.test(e)) {
        this.$v.form.phone.$model = '';
      }
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        this.$v.form.phone.$model = '+' + this.$v.form.phone.$model.replace(/[^0-9]/g, '');
        const response = await contractorCreate(this.formStr);
        if (response && response.status === 201) {
          this.showSuccess('Аккаунт успешно создан');
          this.$router.push({path: '/registration-success'});
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    // register (recaptchaToken) {
    //   axios.post('https://yourserverurl.com/register', {
    //     email: this.email,
    //     password: this.password,
    //     recaptchaToken: recaptchaToken
    //   })
    // },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
      // this.$refs.recaptcha.execute()
    },
    // onCaptchaExpired () {
    //   this.$refs.recaptcha.reset()
    // }
  },
};
</script>

<style lang="scss" scoped>

</style>
